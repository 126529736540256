






import defaultComponent from "../utils/defaultComponent";

export default defaultComponent.apply({});
